<template>
    <div>
        <div class="text-center text-muted" v-if="isLoading">Cargando...</div>
        <div v-if="op==='item' && seeprice"><!-- BEGIN DIV PRICE ITEM-->
            <input type="hidden" :id="'price_'+action+'_'+item" v-model="pricedb">
            <input type="hidden" :id="'discount_'+action+'_'+item" v-model="discount">
            <div v-if="pricedb <= 0" class="d-flex justify-content-between align-self-center">
                <span v-if="msi" class="float-rights">
                    <img :src="ubk+'media/msi.svg'" class="cursor imgBradge3" alt="msi" width="50"/>
                </span>
                <span class="product-card__price">{{oldprice | currency}}</span>
            </div>
            <div v-else class="d-flex justify-content-between align-self-center">
                <span v-if="msi" class="float-rights">
                    <img :src="ubk+'media/msi.svg'" class="cursor imgBradge3" alt="msi" width="50"/>
                </span>
                <div>
                    <span class="product-card__prices">{{ (pricedb <= 0 ? oldprice : pricedb) | currency}}</span>
                    <span class="product-card__old-price" v-if="oldprice>pricedb">{{oldprice | currency}}</span>
                </div>
            </div>
            <div v-if="getCount>1" :class="'d-flex justify-content-end mt-2 '+home">
                <pricesoffertcomponent :offerts="offerts" :ubk="ubk" ></pricesoffertcomponent>
            </div>
        </div><!-- END DIV PRICE ITEM-->
        <div v-if="op==='item-alt' && seeprice">
            <div class="d-flex justify-content-between align-self-center">
                <span v-if="msi" class="float-rights">
                    <img :src="ubk+'media/msi.svg'" class="cursor imgBradge3" alt="msi" width="50"/>
                </span>
                <span>{{ (pricedb <= 0 ? oldprice : pricedb) | currency}}</span>
                <div v-if="getCount>1" class="pl-2">
                    <pricesoffertcomponent :offerts="offerts" :ubk="ubk" ></pricesoffertcomponent>
                </div>
                
            </div>
        </div>

        <div class="new-badge-discount">
            <div v-if="discount>0">
                <img :src="ubk+'media/'+discount+'.svg'" class="cursor imgBradge" :alt="'discount '+discount" width="15"/>
            </div>
            <div v-if="row.payfor!=='x'" class="mt-1">
                <img :src="ubk+'media/'+row.payfor+'.svg'" class="cursor imgBradge" alt="oferta por cantidad" width="15"/>
            </div>
            <div v-if="isFree==='Y'" class="mt-1">
                <img :src="ubk+'media/free_send.svg'" class="cursor imgBradge" alt="Envio Gratis" width="15"/>
            </div>
        </div>

        <div v-if="hot_sale" class="new-badge-iconpubli">
            <img :src="ubk+'media/hot-week-logo.png'" class="cursor" :alt="'HotWeek discount '+discount" style="width: 30%!important;" />
        </div>
        <div v-if="buenfin" class="new-badge-iconpubli">
            <img :src="ubk+'media/buen_fin.svg'" class="cursor imgBradge2" :alt="'discount '+discount" width="60"/>
        </div>
        <div v-if="backfriday" class="new-badge-iconpubli">
            <img :src="ubk+'media/backfriday.svg'" class="cursor imgBradge2" :alt="'discount '+discount" width="60"/>
        </div>
        
    </div>
</template>

<script>
    import moment from "moment";
    import pricesoffertcomponent from './PricesOffertComponent';
    export default {
        props: ['op','item','price','stock','options','action','ubk', 'row', "home" ],
        data () {
            return {
                api:process.env.MIX_API_URL,
                userData:null,
                isLoading: false,
                pricedb:0,
                discount:0,
                offerts:null,
                oldprice:0,
                showicon:false,
                hot_sale:false,
                buenfin:false,
                backfriday:false,
                msi:false,
                isFree: 'N',
                seeprice: true,
                today: moment().format('MMDD'),
            }
        },
        components: { pricesoffertcomponent},
        computed: {
            getCount : function(){
                if(this.offerts===null) return 0;
                else return this.offerts.length;
            },
        },
        mounted() {
            this.userData = this.options;
            this.oldprice = this.price;
            this.seeprice = this.options.showcart;
            this.loadItems();
            //console.log('Home', this.home);
        },
        methods: {
            async loadItems(){
                this.isLoading = true;
                let vm = this;
                let data = {
                    'SPName':'spWebOptionsPublic',
                    'Param1':'GET-PRICES',
                    'Param2':this.item,
                    'Param3':this.userData.CardCode,
                    'Param4':`${this.userData.list_price}`,
                    'Param5':'1',
                    'Param6':this.userData.list_price_type,
                    'Param7':'','Param8':'','Param9':'','Param10':''};
                //console.log(data);
                await axios
                    .post(this.api + "/spmodule/callsp",data)
                    .then(({ data }) => {
                        var result = data;
                        vm.offerts = result;
                        //console.log('Prices',data);
                        result.forEach(element => { //console.log('Prices',element);
                            if(element.Type==='P'){
                                this.discount = JSON.parse(JSON.stringify(element.Amount));
                                var newpricesdb = JSON.parse(JSON.stringify(element.Price));
                                this.pricedb = newpricesdb;
                                this.oldprice = JSON.parse(JSON.stringify(element.Discount));
                                if(parseInt(JSON.parse(JSON.stringify(element.Amount)))>0){
                                    //console.log('Price',element);
                                    if(this.today >= parseInt('0515') && this.today <= parseInt('0523') ){ this.hot_sale = true; }
                                    if(this.today >= parseInt('1115') && this.today <= parseInt('1118') ){ this.buenfin = true; }
                                    if(this.today >= parseInt('1124') && this.today <= parseInt('1127') ){ this.backfriday = true; }
                                    
                                }
                                if(parseInt(element.Price)>1500 && this.userData.WHOLESALER=='N'){ this.msi = true;}
                                this.loadItemPropieties(newpricesdb);
                            }
                        });
                        //console.log(vm.offerts);
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        this.isLoading = false;
                    });
            },// end function load api
            async loadItemPropieties(price){
                let vm = this;
                await axios
                    .post(this.ubk + "getitemspropieties/"+this.item)
                    .then(({data}) => {
                        var result = data;
                        //console.log('Items',result);
                        if(this.userData.SALESMAN==='Y' || this.userData.WHOLESALER==='Y'){
                            vm.isFree = "N";
                        }else{
                            if(result.free==='Y'){
                                if(price>=2500) vm.isFree = "Y";
                            }
                        }
                        //console.log('Items',vm.item,vm.isFree,price);
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {

                    });
            },// end function load api
            onCancel() {
              console.log('User cancelled the loader.')
            }
        },
    }
</script>

<style scoped>
.imgBradge {
    width: 15px!important;
}
.imgBradge2 {
    width: 60px!important;
}
.imgBradge3 {
    width: 50px!important;
}
.new-badge-iconpubli2{
    position: absolute;
    top: 200px;
    right: 5px;
}
.float-rights{
    height: 10px;
    padding: 0;
    margin: 0;
}
</style>
